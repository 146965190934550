// BootstrapのJavaScript側の機能を読み込む
import "bootstrap";

import Stickyfill from "stickyfilljs";

// スタイルシートを読み込む
import "./index.scss";

$(function(){
  function initFontSize() {
    var size = (sessionStorage.getItem('fontSize'))? sessionStorage.getItem('fontSize') : '16';
    changeFontSize(size);
  }
  
  function changeFontSize(size){
    $('html').css('font-size', size + 'px');
    $('[data-font!=' + size + ']').removeClass('active');
    $('[data-font=' + size + ']').addClass('active');
    sessionStorage.setItem('fontSize', size);
  }
  
  function addListeners() {
    $('[data-font]').on('click', function(){
      changeFontSize($(this).data('font'));
    });
  }
  
  function init() {
    initFontSize();
    addListeners();
  }
  
  init();
});

$(function() {
  $("#chgBlack").click(function() {
    $('body').css("background-color","#000");
    $('.bg-info').css("background-color","#000");
    $('body').css("color","#fff");
    $('.bg-info').css("color","#000");
    
  });
  $("#chgWhite").click(function() {
    $('body').css("background-color","#fff");
    $('body').css("color","#000");
    $('td').css("color","#000");
    
  });
  $("#chgYellow").click(function() {
    $('body').css("background-color","#ffc107");
    $('body').css("color","#000");
    $('td').css("color","#000");
  });
});

$(function() {
  $("#chgBlack2").click(function() {
    $('body').css("background-color","#000");
    $('.bg-info').css("background-color","#000");
    $('body').css("color","#fff");
    $('.bg-info').css("color","#000");
    
  });
  $("#chgWhite2").click(function() {
    $('body').css("background-color","#fff");
    $('body').css("color","#000");
    $('td').css("color","#000");
    
  });
  $("#chgYellow2").click(function() {
    $('body').css("background-color","#ffc107");
    $('body').css("color","#000");
    $('td').css("color","#000");
  });
});


$(function() {
  var topBtn = $('#btn_page_top');    
  topBtn.hide();
  //スクロールが500に達したらボタン表示
  $(window).scroll(function () {
      if ($(this).scrollTop() > 500) {
          topBtn.fadeIn();
      } else {
          topBtn.fadeOut();
      }
  });
  //スクロールしてトップ
  topBtn.click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
  });
});

$(function(){
  var elements = $('.sticky');
  Stickyfill.add(elements);
});
